import React, { useRef, useState } from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import ItemIconButtonV2 from '../items/ItemIconButtonV2'
import Icon from '@/components/Icon'
import BaseRichText from '@/v2/base/BaseRichText'
import UtilLinkV2 from '../utils/UtilLinkV2'

type Props = {
  blok: SbBlokData & ISbSectionFaqV2
}

const SectionFaqV2 = ({ blok }: Props) => {
  // open FAQ item state
  const [openFaq, setOpenFaq] = useState<number | null>(null)
  const contentRefs = useRef<(HTMLDivElement | null)[]>([])

  const handleFaqClick = (index: number) => {
    setOpenFaq(openFaq === index ? null : index)
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className={`container-v2 mx-auto grid gap-x-8 gap-y-10 px-4 py-16 sm:px-8 lg:py-24 ${blok.hide_infos || blok.is_info_header ? '' : 'lg:grid-cols-2'}`}
    >
      {/* Left side */}
      <div className={`flex flex-col justify-start gap-8 sm:gap-10${blok.hide_infos ? ' hidden' : ''}`}>
        <div className="grid max-w-5xl gap-2">
          {!!blok.profile_images?.length && !blok.is_info_header && (
            <div className="relative mb-4 flex">
              {/* Storyblok can not set a limit on images */}
              {blok.profile_images.slice(0, 2).map((image, index) => (
                <div
                  key={index}
                  className={`relative flex h-16 w-16 items-center justify-center overflow-hidden rounded-full border-4 border-white ${index > 0 ? '-ml-4' : ''}`}
                >
                  <div className="relative flex h-16 w-16 items-center justify-center [&_img]:h-16 [&_img]:w-16">
                    <BaseImageV2 key={index} src={image.filename} alt={image.alt} objectCover breakPointsWidthMap={{ 0: 64 }} />
                    <div className="absolute left-0 top-0 h-full w-full rounded-full border border-v2-foreground-static-tertiary"></div>
                  </div>
                </div>
              ))}
              <div className="z-10 -ml-6 mt-auto flex h-6 w-6 items-center justify-center rounded-full bg-v2-primary-static-1">
                <Icon name="support" className="h-4 w-4 text-white" />
              </div>
            </div>
          )}
          {blok.kicker && <span className="heading-6 text-v2-text-primary">{blok.kicker}</span>}
          <div>
            {blok.title && <h2 className="heading-3 text-v2-text-primary ">{blok.title}</h2>}
            {blok.subtitle && <h2 className="heading-3 text-v2-text-secondary">{blok.subtitle}</h2>}
          </div>
          {blok.text && <p className="body-1 whitespace-pre-line text-v2-text-secondary">{blok.text}</p>}
        </div>
        {!blok.is_info_header && (
          <div className="flex flex-col items-start gap-6">
            {blok.links.map((faqItem, index) =>
              faqItem?.link?.url ? (
                <UtilLinkV2 key={index} blok={faqItem} size="lg" />
              ) : (
                <div key={index}>
                  <div className="flex items-center gap-2">
                    {faqItem.icon && (
                      <BaseImageV2 src={faqItem.icon.filename} alt={faqItem.icon.alt} breakPointsWidthMap={{ 0: 24 }} />
                    )}
                    <div className="body-1-bold whitespace-pre-line text-v2-text-primary">{faqItem.title}</div>
                    <p className="body-1 text-v2-text-primary">{faqItem.additional_text}</p>
                  </div>
                </div>
              ),
            )}
          </div>
        )}
      </div>
      {/* FAQ Side */}
      <div
        className={`mx-auto w-full place-self-start rounded-5xl border border-v2-foreground-static-tertiary px-4 sm:px-10 ${blok.is_info_header ? '' : 'max-w-5xl'}`}
      >
        {blok.faq_items.map((faq, index) => (
          <div
            {...storyblokEditable(faq)}
            key={index}
            onClick={() => handleFaqClick(index)}
            className={`trainsition cursor-pointer py-8 ${index >= 1 ? 'border-t border-v2-foreground-static-tertiary' : ''}`}
          >
            {/* Question */}
            <div className="flex select-none items-center justify-between gap-6">
              <span className={`heading-5 ${openFaq === index ? 'text-v2-text-primary' : 'text-v2-text-secondary'}`}>
                {faq.title}
              </span>
              <ItemIconButtonV2
                icon="plus"
                className={`transition ${openFaq === index ? '!rotate-45' : ''}`}
                ariaLabel="Toggle FAQ"
              />
            </div>
            {/* Answer */}
            <div
              ref={(el) => (contentRefs.current[index] = el)}
              className={`overflow-hidden transition-all duration-300 ease-in-out ${openFaq === index ? 'opacity-100' : 'opacity-0'}`}
              style={{ maxHeight: openFaq === index ? `${contentRefs.current[index]?.scrollHeight}px` : '0' }}
            >
              <BaseRichText
                value={faq.richtext}
                classNames={{
                  container: 'body-2 text-v2-text-primary pt-6 prose prose-p:whitespace-pre-line',
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SectionFaqV2
